import styled from 'styled-components';

import { BREAKPOINTS, COLORS } from '../../../utils/theme';

export const SubTitleBoxesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 0 60px;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    padding: 20px;
  }
`;

export const SubTitleBoxButton = styled.a`
  display: flex;
  width: 110px;
  border: none;
  background-color: ${COLORS.WHITE};
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 1px 0 3px;
  background-color: ${COLORS.BLUE};
  color: ${COLORS.WHITE};
  border-radius: 15px;
  z-index: 1000;
  cursor: pointer;
  transition: opacity 0.5s;
  text-decoration: none;
  margin-top: 10px;
`;

export const SubTitleBox = styled.div`
  max-width: 385px;
  margin-right: 60px;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
`;

export const SubTitleBoxTitle = styled.h2`
  font-weight: 600;
  font-size: 14px;
`;

export const SubTitleBoxParagraph = styled.p`
  margin: 0;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
`;

export const SubTitleBoxLink = styled.a`
  display: block;
  font-weight: 600;
  text-decoration: none;
  color: black;
  line-height: 32px;

  ${(props) =>
    props.href &&
    `
    background-size: 0;
    background-repeat: no-repeat;
    background-position: bottom 5px center;
    background-image: url('/images/brush-01.svg');

    &:hover {
      background-size: contain;
    }
  `}
`;
